const ACTION_SET_LOCALE = 'ACTION_SET_LOCALE';

const initialState = {
    locale: "und"
};

function translateReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_SET_LOCALE: {
            const { locale } = action.payload;
            return {
                ...state,
                locale
            };
        }
        default:
            return state;
    }
}

const setLocale = locale => ({
    type: ACTION_SET_LOCALE,
    payload: {
        locale
    }
})

export { translateReducer, setLocale }
