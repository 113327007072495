import React from 'react';
import clsx from 'clsx';
import { compose } from 'redux'
import { connect } from 'react-redux'
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import PaletteIcon from '@material-ui/icons/Palette';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import BrushIcon from '@material-ui/icons/Brush';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HelpIcon from '@material-ui/icons/Help';
import InfoIcon from '@material-ui/icons/Info';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { makeStyles } from '@material-ui/core/styles';
import { disableAll, setPaymentMethods, setAccountSettings, setThemeSettings, setAddFirm, setDrawerOpen } from '../../redux/action/appOps'
import myLawyerLogo from '../../logo.svg';
import myLawyerRoundel from '../../roundel.svg';
import firebase from 'firebase/app'
import 'firebase/auth'
import LogoEditorDialog from '../LogoGenerator/LogoEditorDialog';
import { actionTypes } from 'redux-firestore'

const drawerWidthClosed = 75;
const drawerWidthOpen = 220;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  logoArea: {

  },
  roundelArea: {
    padding: '0px 10px 0px 5px',
    transition: theme.transitions.create('padding', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  logo: {
    width: '95%',
    padding: '10px 5px 12px 5px'
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidthClosed,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidthOpen,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toggleArea: {
    display: 'flex',
    justifyContent: 'center'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function PrimarySideMenu(props) {

  const { dispatch, side_menu } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [logoCreateOpen, setLogoCreateOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setOpen(!open);
    dispatch(setDrawerOpen(!open));
  };

  const _handleButtonClick = (e) => {
    dispatch(disableAll());
    let id = e.currentTarget?.dataset?.id;
    switch(id) {
      case 'payment_methods': {
        let active = side_menu?.payment_methods || false;
        dispatch(setPaymentMethods(!active));
        break;
      }
      case 'account_settings': {
        let active = side_menu?.account_settings || false;
        dispatch(setAccountSettings(!active));
        break;
      }
      case 'theme_settings': {
        let active = side_menu?.theme_settings || false;
        dispatch(setThemeSettings(!active));
        break;
      }
      case 'create_logo': {
        setLogoCreateOpen(true);
        break;
      }
      case 'logout': {
        // firebase.auth().signOut();
        firebase.logout().then(() => {
          dispatch({ type: actionTypes.CLEAR_DATA })
        })
        break;
      }
      case 'add_firm': {
        let active = side_menu?.add_firm || false;
        dispatch(setAddFirm(!active));
        break;
      }
    }
  }

  const _closeLogoEditor = (e) => {
    setLogoCreateOpen(false);
  }

  const drawer = (
    <div>
      <List>
        {[
          {
            text: 'Account Settings',
            key: 'account_settings',
            icon: (<AccountBoxIcon/>)
          },{
            text: 'Logo creation',
            key: 'create_logo',
            icon: (<BrushIcon/>)
          },{
            text: 'Theme Settings',
            key: 'theme_settings',
            icon: (<PaletteIcon/>)
          },{
            text: 'Payment Methods',
            key: 'payment_methods',
            icon: (<CreditCardIcon/>)
          }].map((item, index) => (
          <ListItem button key={item.key} data-id={item.key} onClick={_handleButtonClick}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {[{
            text: 'Help',
            key: 'help',
            icon: (<HelpIcon />),
            link: 'https://www.mylawyernetwork.com/help'
          },{
            text: 'About',
            key: 'about',
            icon: (<InfoIcon />),
            link: 'https://www.mylawyernetwork.com/about'
          }].map((item, index) => (
          <a href={item.link} target="_blank">
            <ListItem button key={item.key} data-id={item.key}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItem>
          </a>
        ))}
      </List>
      <Divider />
      <List>
        {[
          {
            text: 'Logout',
            key: 'logout',
            icon: (<ExitToAppIcon/>)
          }].map((item, index) => (
          <ListItem button key={item.key} data-id={item.key} onClick={_handleButtonClick}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  /*
  Before logout list item
      <List>
        {[
          {
            text: 'Add Firm',
            key: 'add_firm',
            icon: (<AddIcon/>)
          }].map((item, index) => (
          <ListItem button key={item.key} data-id={item.key} onClick={_handleButtonClick}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>
      <Divider />
  */

  return (
    <div className={classes.root}>
      <nav className={classes.drawer} aria-label="App additional actions">
        <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            })}
            classes={{
              paper: clsx({
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              }),
            }}
          >
          <div className={open ? classes.logoArea : classes.roundelArea} ><img src={open ? myLawyerLogo : myLawyerRoundel} alt="MyLawyer" className={classes.logo}/></div>
          <Divider />
          <div className={classes.toggleArea}>
            <IconButton onClick={handleDrawerToggle}>
              { open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </div>
          <Divider />
          { drawer }
        </Drawer>
      </nav>
      <LogoEditorDialog open={logoCreateOpen} onComplete={_closeLogoEditor}/>
    </div>
  );
}


const enhance = compose(
  connect(
      // Map redux state to component props
      ({
          appOps: { side_menu }
      }) => ({
          side_menu
      })
  )
)

export default enhance(PrimarySideMenu);