import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux'
import HeaderComponent from './HeaderComponent'
import FooterComponent from './FooterComponent'
import OnBoardingContent from './OnBoardingContent'
import { setOnBoardingPageID } from '../../redux/action/appOps'

function OnBoardingPage(props) {

    const { dispatch, onboarding_pid } = props;

    if (typeof onboarding_pid == 'undefined') {
        dispatch(setOnBoardingPageID(0));
    }

  return (
    <React.Fragment>
        <HeaderComponent />
        <OnBoardingContent />
        <FooterComponent />
    </React.Fragment>
  )
}

const enhance = compose(
  connect(
      // Map redux state to component props
      ({
            firebase: { auth },
            appOps: { onboarding_pid }
      }) => ({
            onboarding_pid
      })
  )
)

export default enhance(OnBoardingPage);