import React, { useEffect, useState }  from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { SketchPicker } from 'react-color';
import FontPicker from "font-picker-react";
import TextField from '@material-ui/core/TextField'
import { Grid, Paper, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      alignItems: "center",
      backgroundColor: '#efefef',
      marginBottom: 10,
      borderBottom: '10px solid #ffffff',
      padding: 10
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      color: theme.palette.text.secondary,
      marginRight: 10,
    },
    title: {
        marginBottom: 10,
        width: '100%',
        textAlign: 'center'
    }
  }));

export default function StyleTextInput(props) {
    const classes = useStyles();

    const { id, lineNr, text, initialColor, initialFont, onTextChange, onStylingChange } = props;

    const [color, setColor] = useState('');
    const [font, setFont] = useState('');

    useEffect(()=>{
        setColor(initialColor ? initialColor : '#0062b1');
        setFont(initialFont ? initialFont : '');
    },[])

    const sendStylingChange = () => {
        if (onStylingChange) {
            let s = {
                'color': color,
                'font': font
            }
            onStylingChange(id, s);
        }
    }

    const handleColorChange = (color) => {
        setColor(color.hex);
        sendStylingChange();
      };
    const handleFontChange = (font) => {
        setFont(font.family);
        sendStylingChange();
      };
      
    let fontPicker;
    let fontAPI = process.env.REACT_APP_FIREBASE_API_KEY_FONT;
    
    if (font) {
        fontPicker = (
            <FontPicker
                apiKey={fontAPI}
                activeFontFamily={font}
                onChange={ (nextFont) => handleFontChange(nextFont) }
            />
        )
    } else {
        fontPicker = (
            <FontPicker
                apiKey={fontAPI}
                onChange={ (nextFont) => handleFontChange(nextFont) }
            />
        )
    }

    return (
        <React.Fragment>
            <Grid container justify="center" spacing={3} className={classes.root}>
                <Grid item xs={12} sm={1}>
                    Group {lineNr}
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextField id={id} label="Logo word" variant="outlined" value={text} onChange={ (e) => onTextChange(id, e.currentTarget.value) }/>
                </Grid>
                <Grid xs={12} sm={3}>
                    <Paper className={classes.paper}><Typography className={classes.title}>Choose color</Typography><SketchPicker disableAlpha={true} color={color} onChangeComplete={ handleColorChange }/></Paper>
                </Grid>
                <Grid xs={12} sm={5}>
                    <Paper className={classes.paper}><Typography className={classes.title}>Choose font</Typography>{ fontPicker }</Paper>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}