import {
    ACTION_BILLING_CUSTOMER_FETCH_REQUEST,
    ACTION_BILLING_CUSTOMER_FETCH_RESULT,
} from "../action/type";

function billingCustomerFetch(state = {}, action) {
    switch (action.type) {
        case ACTION_BILLING_CUSTOMER_FETCH_REQUEST: {
            return Object.assign({}, state, {
                fetching: true
            });
        }
        case ACTION_BILLING_CUSTOMER_FETCH_RESULT: {
            return Object.assign({}, state, {
                fetching: false,
                data: action.customer,
                updated: action.updated
            });
        }
        default:
            return state
    }
}

export default function billingReducer(state = {}, action) {
    switch (action.type) {
        case ACTION_BILLING_CUSTOMER_FETCH_REQUEST:
        case ACTION_BILLING_CUSTOMER_FETCH_RESULT: {
            return Object.assign({}, state, {
                customer: Object.assign({}, state?.customer, {
                    data: billingCustomerFetch(state?.['customer'], action)
                })
            });
        }
        default:
            return state;
    }
}
