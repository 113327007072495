import React from "react";

import MenuTabs from './Tabs'

function MainMenu(props) {
    return (
        <MenuTabs menu={props.menu} />
    )
}

export default MainMenu
