import React, { useEffect, useState, useRef }  from 'react';

export default function LogoSvg(props) {

    const { svgRef, lines, backgroundColor } = props;

    const [scale, setScale] = useState(1);

    const getTextBBox = () => {
        const svg = svgRef.current;
        const logoText = svg.querySelector('.logoText')
        const svgBBox = svg.getBoundingClientRect();
        const textBBox = logoText.getBBox();
        if (textBBox.width > svgBBox.width) {
            setScale(0.8*(svgBBox.width/textBBox.width));
        }
        console.log(svgBBox, textBBox, scale);
        
    }

    useEffect(() => {
        getTextBBox();
    }, [lines])

    let styledLines = [];
    let fonts = new Set();
    let y=0;
    for (const [id, line] of Object.entries(lines)) {
        styledLines.push(<tspan
            key={id}
            id={id}
            style={{
                "fontSize": 50 * scale + 'px',
                "lineHeight":1.25,
                "fontFamily": line.font ? line.font: 'Open+Sans',
                "fill": line.color ? line.color : '#000000',
            }}>
            {line.word}
            </tspan>)
        //Add a space after
        styledLines.push(<tspan style={{"fontSize": '10px'}}> </tspan>);
        if (line.font) {
            fonts.add(line.font);
        }
    }


    let fontImports = '';
    [...fonts].forEach((fontFamily) => {
        fontImports = fontImports + `@import url('https://fonts.googleapis.com/css?family=${fontFamily}`;
    });

    return (
        <svg
            ref={svgRef}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 400 80"
            width="400px"
            height="80px"
            preserveAspectRatio="xMinYMin meet">
            <defs>
                <style type="text/css">
                    {fontImports}
                </style>
            </defs>
            <rect x={0} y={0} width={400} height={80} fill={backgroundColor}/>
            <g transform="translate(0, 0)">
                <text class="logoText" x="50%" y="20%" width="400" dominant-baseline="hanging" text-anchor="middle" wordSpacing={2}>
                    {styledLines}
                </text>
            </g>
        </svg>
    )
}