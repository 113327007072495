/**
 * This Theme will override and extend the default Material-UI theme
 * See https://material-ui.com/customization/default-theme/
 */

const colour = {
    primary: '#3d4f62',
    secondary: '#808ba2',
    accent: '#d42617',
    accentHover: '#e9483a',
    darkGrey: '#757575'
};

export default {
    palette: {
        text: {
            primary: colour.primary,
            secondary: colour.secondary
        },
        secondary: {
            light: colour.accentHover,
            main: colour.accent,
        }
    },
    typography: {
        fontFamily: '\'Lato\', sans-serif'
        // fontFamily: [
        //   '\'Lato\', sans-serif',
        //   '\'Roboto Slab\', serif',
        //   '\'Playfair Display\', serif',
        // ].join(','),        
    },
    shape: {
        borderRadius: 0
    },
    mylawyer: {
        background: {
            white: '#ffffff',
            light: '#e7ecf3',
            medium: '#d2d9e2',
            dark: colour.primary,
            accent: colour.accent,
            input: '#ffffff'
        },
        colour,
        button: {
            primary: colour.accent,
            primaryHover: colour.accentHover,
        },
        color: {
            red: '#e73137',
            midGrey: '#d2d9e2',
            darkGrey: '#757575'
        },
        mention: {
            foreground: colour.accent,
            background: '#fde8e7'
        }
    },
    colour: {
        red: '#e73137',
        midGrey: '#d2d9e2',
        darkGrey: '#757575'
    }
};
