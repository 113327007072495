export const ACTION_PROFILE_USER_SELECT = 'ACTION_PROFILE_USER_SELECT';

export const ACTION_APP_OPS_SIDEMENU_PAYMENT_METHODS_ACTIVE = 'ACTION_APPOPS_SIDEMENU_PAYMENT_METHODS_ACTIVE';
export const ACTION_APP_OPS_SIDEMENU_ACCOUNT_SETTINGS_ACTIVE = 'ACTION_APP_OPS_SIDEMENU_ACCOUNT_SETTINGS_ACTIVE';
export const ACTION_APP_OPS_SIDEMENU_THEME_SETTINGS_ACTIVE = 'ACTION_APP_OPS_SIDEMENU_THEME_SETTINGS_ACTIVE';
export const ACTION_APP_OPS_SIDEMENU_ADD_FIRM_ACTIVE = 'ACTION_APP_OPS_SIDEMENU_ADD_FIRM_ACTIVE';
export const ACTION_APP_OPS_SIDEMENU_DISABLE_ALL = "ACTION_APP_OPS_SIDEMENU_DISABLE_ALL";
export const ACTION_APP_OPS_SIDEMENU_DRAWER_OPEN = "ACTION_APP_OPS_SIDEMENU_DRAWER_OPEN";
export const ACTION_APP_OPS_SET_ONBOARDING_PAGE_ID = "ACTION_APP_OPS_SET_ONBOARDING_PAGE_ID";

export const ACTION_BILLING_CUSTOMER_FETCH_REQUEST = 'ACTION_BILLING_CUSTOMER_FETCH_REQUEST';
export const ACTION_BILLING_CUSTOMER_FETCH_RESULT = 'ACTION_BILLING_CUSTOMER_FETCH_RESULT';

export const ACTION_FIREBASE_AUTH_SIGN_IN = 'ACTION_FIREBASE_AUTH_SIGN_IN'
export const ACTION_FIREBASE_AUTH_SIGN_OUT = 'ACTION_FIREBASE_AUTH_SIGN_OUT'
export const ACTION_FIREBASE_AUTH_ID_TOKEN_RECEIVED = 'ACTION_FIREBASE_AUTH_ID_TOKEN_RECEIVED'

export const ACTION_LAWFIRM_SELECT = "ACTION_LAWFIRM_SELECT"
export const ACTION_LAWFIRM_LAWYER_SELECT = "ACTION_LAWYER_SELECT"
export const ACTION_LAWFIRM_LAWYER_MORE = "ACTION_LAWYER_MORE"
export const ACTION_LAWFIRM_UNALLOCATED_LAWYER_MORE = "ACTION_LAWFIRM_UNALLOCATED_LAWYER_MORE"
export const ACTION_LAWFIRM_LICENSE_MORE = "ACTION_LAWFIRM_LICENSE_MORE"
export const ACTION_LAWFIRM_LICENSE_LAWYER_SELECT = "ACTION_LAWFIRM_LICENSE_LAWYER_SELECT"
