import 'firebase/auth'
import 'firebase/database'

import {
    ACTION_LAWFIRM_SELECT,
    ACTION_LAWFIRM_LAWYER_SELECT,
    ACTION_LAWFIRM_LICENSE_LAWYER_SELECT,
    ACTION_LAWFIRM_LAWYER_MORE,
    ACTION_LAWFIRM_UNALLOCATED_LAWYER_MORE,
    ACTION_LAWFIRM_LICENSE_MORE
} from './type'

export function firmSelect(firm_id) {
    return {
        type: ACTION_LAWFIRM_SELECT,
        firm_id,
    }
}

export function lawyerSelect(lawyer_uid) {
    return {
        type: ACTION_LAWFIRM_LAWYER_SELECT,
        lawyer_uid
    }
}

export function licenseLawyerSelect(license_lawyer_uid) {
    return {
        type: ACTION_LAWFIRM_LICENSE_LAWYER_SELECT,
        license_lawyer_uid
    }
}

export function lawyerMore() {
    return {
        type: ACTION_LAWFIRM_LAWYER_MORE,
    }
}

export function unallocatedLawyerMore() {
    return {
        type: ACTION_LAWFIRM_UNALLOCATED_LAWYER_MORE
    }
}

export function licenseMore() {
    return {
        type: ACTION_LAWFIRM_LICENSE_MORE,
    }
}