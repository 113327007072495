import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { all as strings } from './strings'

export function translate(key, locale = "en") {
    return strings?.[locale]?.[key] || strings?.[key] || key;
}

function Translate(props) {

    const { value, locale } = props;

    return (
        <React.Fragment>
            {translate(value, locale)}
        </React.Fragment>
    )
}

const enhance = compose(
    connect(
        // Map redux state to component props
        ({ translate: { locale } }) => ({ locale })
    )
)

export default enhance(Translate);

