import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import MainMenu from './Menu'
import strings from '../../translate/strings'
import LawfirmProfileCompletionWizard from '../Blade/LawfirmProfileCompletionWizard';

const FirmProfile = lazy(() => import('../../pages/FirmProfile'));
const FirmLicenses = lazy(() => import('../../pages/FirmLicenses'))
const FirmLawyers = lazy(() => import('../../pages/FirmLawyers'));

const menuLibrary = [
    {
        path: "/", label: strings.main_menu_firm_page, route: (<FirmProfile />)
    },
    {
        path: "/lawyers", label: strings.main_menu_firm_lawyers, route: (<FirmLawyers />)
    },
    {
        path: "/licenses", label: strings.main_menu_firm_seats, route: (<FirmLicenses />)
    }
];

const routeLibrary = menuLibrary.map(currentMenuItem =>
    (
        <Route
            exact path={currentMenuItem.path}
            key={currentMenuItem.path}>
            {currentMenuItem.route}
        </Route>
    ));


function MenuRouter(props) {
    return (
        <Router>
            <MainMenu menu={menuLibrary} />
            <Suspense fallback={<div>Loading...</div>}>
                <Switch>
                    {routeLibrary}
                </Switch>
            </Suspense>
            <div className="appTray right network-blades"><LawfirmProfileCompletionWizard /></div>
        </Router>
    )
}

export default MenuRouter;