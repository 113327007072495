import stringsCustom from './stringsCustom'
/**
 * Default translation
 * @class
 */
let strings = {
    app_name: "myLawFirm App",
    ...stringsCustom.und,
};

export const all = { ...strings, ...stringsCustom };

const stringKeys = Object.keys(all).reduce((acc, cur) => {
    if (typeof strings[cur] === 'string') {
        acc[cur] = strings[cur];
    }
    return acc;
}, {});

/**
 * @returns {strings}
 */
function keys() {
    return stringKeys;
}

export default keys();
