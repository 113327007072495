import firebase from 'firebase/app'
import 'firebase/auth'
const axios = require('axios').default;

export const braintreeToken = () => {
    const currentUser = firebase.auth().currentUser;
    if (!currentUser) {
        return Promise.reject("no user");
    }
    return currentUser.getIdToken(true).then(function(idToken) {
      return fetch(process.env.REACT_APP_FUNCTION_URI + '/firm_payments/braintree/client-token', { /*'https://mylawyer-braintree-bridge.appspot.com/braintree/token', { */
        method: 'POST',
        headers: {
          'Accept': 'text/plain',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          'firebaseToken': idToken
        })
      })
      .then((response) => {
        if (!response.ok) {
          return {};
        }
        return response.json();
      })
      .then((responseJson) => {
        return responseJson['token'];
      })
      .catch((error) => { console.error(error); throw error; });
    });
  }

let customerDataRequest;

export const braintreeCustomer = (user_id, firmId, 
  onSuccess = (user_id, hits) => { },
  onError = (user_id, error) => { }) => {

  const currentUser = firebase.auth().currentUser;
  if (!currentUser || currentUser.uid != user_id) {
      console.warn("no authenticated user")
      onError(user_id)
      return;
  }
  currentUser.getIdToken().then((idToken) => {
    if (!idToken) {
        console.warn("no token")
        onError(user_id)
        return;
    }
    
    //cancel any existing request
    customerDataRequest && customerDataRequest.cancel('canceled by user')
    customerDataRequest = axios.CancelToken.source();

    const config = {
      headers: { 'Accept': 'text/plain', 'Content-Type': 'application/json' },
      cancelToken: customerDataRequest.token
    };
    const body = {
      'firebaseToken': idToken,
      'firmId': firmId
    }
    axios
        .post(process.env.REACT_APP_FUNCTION_URI + '/firm_payments/braintree/customer/payment-methods', body,config)
        .then((response) => {
            onSuccess(user_id, response.data)
        }).catch((error) => {
            onError(user_id, error)
        });
  });
}

// cancel 1 license from the firm firmId
export const licenseCancel = (firmId, onSuccess, onError) => {
  const currentUser = firebase.auth().currentUser;
  if (!currentUser) {
      return Promise.reject("no user");
  }
  return currentUser.getIdToken(true).then(function(idToken) {
    return fetch(process.env.REACT_APP_FUNCTION_URI + '/firm_payments/braintree/license/cancel-single', { 
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        'firebaseToken': idToken,
        'firmId': firmId
      })
    })
    .then((response) => {
      if (!response.ok) {
        return {};
      }
      return true;
    })
    .then(() => {
      onSuccess();
    })
    .catch((error) => {
      onError();
      console.error(error); 
      throw error; 
    });
  });
}

export const licenseAdd = (firmId, licenseQuantity, onSuccess, onError) => {
  const currentUser = firebase.auth().currentUser;
  if (!currentUser) {
      return Promise.reject("no user");
  }
  return currentUser.getIdToken(true).then(function(idToken) {
    return fetch(process.env.REACT_APP_FUNCTION_URI + '/firm_payments/braintree/license/create', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        'firebaseToken': idToken,
        'firmId': firmId,
        'licenseQuantity': licenseQuantity
      })
    })
    .then((response) => {
      if (!response.ok) {
        return {};
      }
      return true;
    })
    .then(() => {
      onSuccess();
    })
    .catch((error) => {
      onError();
      console.error(error); 
      throw error; 
    });
  });
}
  
  export const braintreeVaultAdd = (nonce, firmId) => {
    const currentUser = firebase.auth().currentUser;
    if (!currentUser) {
        return Promise.reject("no user");
    }

    return currentUser.getIdToken(true).then(function(idToken) {
      return fetch(process.env.REACT_APP_FUNCTION_URI + '/firm_payments/braintree/payment-method/create', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          'nonce': nonce,
          'firebaseToken': idToken,
          'firmId': firmId
        })
      })
      .then((response) => {
        if (!response.ok) {
          return {};
        }
        return response.json();
      })
      .then((responseJson) => {
        return responseJson;
      })
      .catch((error) => { console.error(error); throw error; });
    });
  }
  
  export const braintreeVaultList = (firmId) => {
    const currentUser = firebase.auth().currentUser;
    if (!currentUser) {
        return Promise.reject("no user");
    }
    return currentUser.getIdToken(true).then(function(idToken) {
      return fetch(process.env.REACT_APP_FUNCTION_URI + '/firm_payments/braintree/customer/payment-methods', { 
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          'firebaseToken': idToken,
          'firmId': firmId
        })
      })
      .then((response) => {
        if (!response.ok) {
          return {};
        }
        return response.json();
      })
      .then((responseJson) => {
        return responseJson;
      })
      .catch((error) => { console.error(error); throw error; });
    });
  }
  
  export const braintreeVaultRemove = (paymentMethodToken, firmId) => {
    const currentUser = firebase.auth().currentUser;
    if (!currentUser) {
        return Promise.reject("no user");
    }
    return currentUser.getIdToken(true).then(function(idToken) {
      return fetch(process.env.REACT_APP_FUNCTION_URI + '/firm_payments/braintree/payment-method/delete', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          'firebaseToken': idToken,
          'paymentMethodToken': paymentMethodToken,
          'firmId': firmId
        })
      })
      .then((response) => {
        if (!response.ok) {
          return {};
        }
        return response.json();
      })
      .then((responseJson) => {
        return responseJson;
      })
      .catch((error) => { console.error(error); throw error; });
    });
  }