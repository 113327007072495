import React, { useEffect, useState } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { useFirebaseConnect, useFirebase, isEmpty } from 'react-redux-firebase'
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import LockIcon from '@material-ui/icons/Lock';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Button, Card, CardContent, Container, Divider, Dialog, DialogActions, 
    DialogTitle, DialogContent, DialogContentText, FormControl, 
    Grid, IconButton, Input, InputLabel, InputAdornment, ListSubheader,
    MenuItem, Select, TextField,  Typography, ListItem, LinearProgress } from "@material-ui/core";

import { welcomeFoundingAdmin } from '../../util/userManagement';

const useStyles = makeStyles(theme => ({
    logoArea: {
        margin: '50px 20px'
    },
    logo: {
        width: '100%'
    },
    message: {
        width: '100%',
        marginBottom: '20px',
        color: theme.mylawyer.colour.red
    },
    loginField: {
        width: '100%',
        marginBottom: '20px'
    },
    textField: {
        width: '100%',
        marginBottom: '20px'
    },
    loginBox: {
        
    },
    loginButton: {
        width: '100%',
        margin: '20px 0px'
    },
    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2),
    },
    centerText: {
        textAlign: 'center',
        width: '100%'
    },
    helpText: {
        textAlign: 'center',
        fontSize: 14,
        fontWeight: 'bold',
        paddingTop: 20,
        paddingBottom: 20
    },
    loginSupport: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'no-wrap',
        padding: 0,
        width: '100%'
    },
    forgotLink: {
        color: theme.mylawyer.red
    },
    goldButton: {
        backgroundColor: '#b08a00',
        color: '#fff',
        borderRadius: '4px'
    }
}));


function LoginScreen(props) {
    const classes = useStyles();
    const { auth, countries } = props;
    const [loadingIndicator, setLoadingIndicator] = useState(true);
    const [message, setMessage] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [reminderPassword, setReminderPassword] = useState(false);
    const [reminderPasswordEmail, setReminderPasswordEmail] = useState('');
    const [newUser, setNewUser] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [displayName, setDisplayName] = useState('');
    const [country, setCountry] = useState('');
    const [profileError, setProfileError] = useState({});
    const loaded = React.useRef(false);

    const firebase = useFirebase();

    useEffect(() => {
        setTimeout(function() {
            setLoadingIndicator(false);
        }, 2000);

        let loginScreenQuery = window.location.search.substring(1);
        if (loginScreenQuery == 'newuser=true') {
            setNewUser(true);
        }
    },[])

    useFirebaseConnect([
        {
            path: "/config/countries",
            storeAs: "countries"
        }
    ])

    const _handleFormChangeEmail = (event) => {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        setUsername(event.target.value)
        let _profileError = profileError;
        delete _profileError.username

        if (!re.test(username)) {
            _profileError.username = 'The email you have entered does not appear to be correct.';
        }
        setProfileError(_profileError);
    }

    const _handleFormChangePassword = (event) => {
        let _profileError = profileError;
        delete _profileError.password;
        setPassword(event.target.value)
        setProfileError(_profileError)
    }

    const _handleFormChangePasswordConfirm = (event) => {
        let _profileError = profileError;
        delete _profileError.passwordConfirm;
        setPasswordConfirm(event.target.value)
        setProfileError(_profileError)
    }

    const _loginWithEmail = (event) => {
        firebase
            .auth()
            .signInWithEmailAndPassword(
                username,
                password)
            .catch(console.error);

        event.preventDefault();
    }

    const _forgotPasswordStart = () => {
      setReminderPassword(true);
    }
    const _forgotPasswordClose = () => {
        setReminderPassword(false);
    }
    const _handleForgotPasswordFormChange = (event) => {
        setReminderPasswordEmail(event.target.value)
    }
    const _forgotPasswordSend = () => {
        firebase.auth().sendPasswordResetEmail(reminderPasswordEmail).then(function() {
            setReminderPassword(false);
            setMessage('You should receive a password reset email shortly.')
        }).catch(error => {
            setReminderPassword(false);
            setMessage('Password reset email could not be sent.  Have you entered the right email address?')
        })
    }

    let passwordReminderDialog = (
        <Dialog onClose={_forgotPasswordClose} aria-labelledby="reminder-dialog-title" open={reminderPassword}>
            <DialogTitle id="reminder-dialog-title">Reset my password</DialogTitle>
            <DialogContent>
            <DialogContentText>
                To have a password reset link sent to your email address please complete the following
            </DialogContentText>
            <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Email Address"
                type="email"
                fullWidth
                onChange={(e) => _handleForgotPasswordFormChange(e) } 
            />
            </DialogContent>
            <DialogActions>
            <Button onClick={_forgotPasswordClose} color="primary">
                Cancel
            </Button>
            <Button onClick={(e) => {_forgotPasswordSend(e)} } color="primary">
                Send
            </Button>
            </DialogActions>
        </Dialog>
    )

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
      };
    
      const handleMouseDownPassword = (event) => {
        event.preventDefault();
      };
    
    const _handleFormChangeDisplayName = (event) => {
        let _profileError = profileError;
        delete _profileError.displayName
        setDisplayName(event.target.value)
        setProfileError(_profileError)
    }

    const _handleFormChangeCountry = (event) => {
        let _profileError = profileError;
        delete _profileError.country
        setCountry(event.target.value)
    }

    const _newUserStart = () => {
        setNewUser(true);
    }

    const _newUserClose = () => {
        setNewUser(false);
    }

    const _newUserSave = () => {

        try {
            let _profileError = profileError;
            if (username.trim() == '') {
                _profileError.username = 'The email address cannot be empty';
            }
            
            if (password.trim().length < 6) {
                _profileError.password = 'The password cannot be empty and must be more than six characters';
            }

            if (passwordConfirm.trim() != password.trim()) {
                _profileError.password = 'The password and confirmed password do not match';
            }

            if (displayName.trim() == '') {
                _profileError.displayName = 'The name cannot be empty';
            }
            if (country.trim() == '') {
                _profileError.country = 'Please select your country';
            }
            setProfileError(_profileError)

            if (Object.keys(profileError).length > 0) {
                return;
            }

            firebase.auth().createUserWithEmailAndPassword(username, password)
            .then(function(response){
                let privateUserProfile = {
                    name: displayName,
                    location_primary: country,
                    location_primary_name: countries[country],
                    profile_is_lawyer: false, //by default the lawfirm admin is NOT a lawyer and NOT visible
                    pref_is_public: false,
                    pref_is_contact_visible: false
                };
                let userId = response.uid ? response.uid : response.user?.uid;
                return firebase.ref(`user_private/${userId}`).update(privateUserProfile);
            })
            .then(() => {
                //send welcome email to admin with login info
                welcomeFoundingAdmin((success) => {
                    //TBD
                }, (error) => {
                    //TBD
                });
            })
            .catch((e) => {
                if (e.code == "auth/email-already-in-use") {
                    setProfileError({ ...profileError, 'general': "You already have an account at this email and do not need to create an account. Please use the login form to login."})
                }
            })
        } catch(e) {
            setProfileError({ ...profileError, 'general': e.message})
        }
          
    }

    let newUserDialog = null;
    if (newUser) {
        let displayCountries = [];
        for (var cy in countries) {
            displayCountries.push([cy, countries[cy]])
        }
        displayCountries.sort(function(a,b) {
            return ('' + a[1]).localeCompare(b[1]);
        })
        let displayCountriesMenuItems = displayCountries.map(country => {
            return (<MenuItem value={ country[0] } key={ country[0] }>{ country[1] }</MenuItem>)
        })
        let errors = [];
        Object.keys(profileError).forEach((key) => {
            let item = profileError[key];
            errors.push(<ListItem key={key}>{item}</ListItem>)
        })

        newUserDialog = (
            <Dialog onClose={_newUserClose} aria-labelledby="new-user-dialog-title" open={newUser}>
                <DialogTitle id="new-user-dialog-title">Register Now</DialogTitle>
                <DialogContent>
                <DialogContentText>
                    Please enter your personal details below.
                </DialogContentText>
                <DialogContentText>
                    {errors}
                </DialogContentText>
                <FormControl className={clsx(classes.margin, classes.textField)}>
                    <InputLabel htmlFor="name" required={true}>Name</InputLabel>
                    <Input
                        id="name"
                        label="Name"
                        type= 'text'
                        required={true}
                        value={displayName}
                        onChange={(e) => _handleFormChangeDisplayName(e) } 
                    />
                </FormControl>
                <TextField
                    className={clsx(classes.margin, classes.textField)}
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Email Address"
                    type="email"
                    fullWidth
                    required={true}
                    value={username}
                    onChange={(e) => _handleFormChangeEmail(e) } 
                />
                <FormControl className={clsx(classes.margin, classes.textField)}>
                    <InputLabel htmlFor="standard-adornment-password" required={true}>Password</InputLabel>
                    <Input
                        id="standard-adornment-password"
                        label="Password"
                        type={showPassword ? 'text' : 'password'}
                        value={password}
                        required={true}
                        onChange={(e) => _handleFormChangePassword(e) } 
                        endAdornment={
                            <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                            >
                                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                            </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
                <FormControl className={clsx(classes.margin, classes.textField)}>
                    <InputLabel htmlFor="standard-adornment-password-confirm" required={true}>Confirm Password</InputLabel>
                    <Input
                        id="standard-adornment-password-confirm"
                        label="Confirm Password"
                        type='password'
                        value={passwordConfirm}
                        required={true}
                        onChange={(e) => _handleFormChangePasswordConfirm(e) } 
                    />
                </FormControl>
                <FormControl className={classes.textField}>
                    <InputLabel id="country-select-label" required={true}>Country</InputLabel>
                    <Select
                        labelId="country-select-label"
                        id="country-select"
                        value={country}
                        required={true}
                        onChange={_handleFormChangeCountry}
                        >
                        <ListSubheader>Selected countries</ListSubheader>
                        <MenuItem value="US" key="US1">United States</MenuItem>
                        <MenuItem value="GB" key="GB1">United Kingdom</MenuItem>
                        <MenuItem value="IN" key="IN1">India</MenuItem>
                        <ListSubheader>All countries</ListSubheader>
                        {displayCountriesMenuItems}
                    </Select>
                </FormControl>
                </DialogContent>
                <DialogActions>
                <Button onClick={_newUserClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={_newUserSave} color="primary">
                    Sign up
                </Button>
                </DialogActions>
            </Dialog>
        );
    }



    let loginForm = null;
    if (!loadingIndicator && isEmpty(auth)) {
        loginForm = (
            <Container className={classes.root}>
                <div className={classes.message}>{message}</div>
                <Typography className={classes.helpText}>If you have an account with myLawFirm please enter your email and password below to login</Typography>
                <Card className={classes.loginBox}>
                    <CardContent>
                        <form noValidate autoComplete="off" onSubmit={(e) => _loginWithEmail(e)}>
                            <TextField className={classes.loginField} id="email" label="Email address" variant="outlined"
                                InputProps={{
                                    startAdornment: (<InputAdornment position="start">
                                        <AccountCircleIcon color="secondary"/>
                                    </InputAdornment>)
                                }} onChange={(e) => _handleFormChangeEmail(e) } />
                            <TextField className={classes.loginField} id="password" label="Password" variant="outlined" type="password"
                                InputProps={{
                                    startAdornment: (<InputAdornment position="start">
                                        <LockIcon color="secondary"/>
                                    </InputAdornment>)
                                }} onChange={(e) => _handleFormChangePassword(e) }/>
                            <Button type="submit" className={classes.loginButton} variant="contained">Login</Button>
                        </form>
                        <Divider/>
                        <Grid container>
                            <ul className={classes.loginSupport}>
                                <li style={{'listStyleType': 'none'}}>
                                    <Button  onClick={ _forgotPasswordStart } color="secondary" className={classes.forgotLink}>
                                        I forgot my password
                                    </Button>
                                </li>
                            </ul>
                        </Grid>
                        <Divider/>
                        <Grid container>
                            <Typography className={classes.helpText}>If you do not have an account with myLawFirm, please click the link below</Typography>
                            <ul className={classes.loginSupport}>
                                <li style={{'listStyleType': 'none', 'fontSize': '20px', 'fontWeight': '700'}}>
                                    <Button variant="contained" className={classes.goldButton} onClick={ _newUserStart }>
                                        Register Now
                                    </Button>
                                </li>
                            </ul>
                        </Grid>
                    </CardContent>
                </Card>
                { passwordReminderDialog }
                { newUserDialog }
            </Container>
        )
    }
    else {
        // Logged in - redirect to main page?
        loginForm = (
            <Container className={classes.root}>
                <div className={classes.message}>{message}</div>
                <LinearProgress/>
            </Container>
        )
    }
    return (
        <div>
            {loginForm}
        </div>
    )
}


const enhance = compose(
    connect(
        // Map redux state to component props
        ({
            firebase: { auth, data: {countries}}
        }) => ({
            auth,
            countries
        })
    )
)

export default enhance(LoginScreen);
