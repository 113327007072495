/**
 * //https://github.com/nirus/SVG-PNG-Convert/blob/master/pages/img-conv.js
 * 
 * @param {string} svg SVG markup 
 * @param {integer} cw Output image width
 * @param {integer} ch Output image height
 * @param {float} scale Resize the scale of your output image
 */
export const convertSVGToImage = (svg, cw, ch, bgColor) =>  {

    return new Promise((resolve, reject) => {
        
        const encodedSvg = btoa(svg);

        //Create the image from the SVG
        const image = new Image();
        image.width = cw;
        image.height = ch;
        image.crossOrigin = "anonymous";
        image.src = 'data:image/svg+xml;base64,' + encodedSvg;

        const canvas = document.createElement("canvas");
        const context = canvas.getContext('2d');
        context.canvas.width = cw;
        context.canvas.height = ch;

        context.fillStyle = bgColor;
        context.fillRect(0, 0, cw, ch);

        image.onload = function (e) {           
            context.drawImage(image, 0, 0, cw, ch);
            canvas.toBlob((blob) => {
                resolve({ image: blob, name: 'logo.png' });
            }, 'image/png')
            
        }

        image.onerror = function (e) {
            reject(e);
        }
    })
};