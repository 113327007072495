import React from 'react'
import { Provider } from 'react-redux'
import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import { store, rrfProps } from './store'

class FirebaseReduxComponent extends React.Component {
    render() {
        return (
            <Provider store={store}>
                <ReactReduxFirebaseProvider {...rrfProps}>
                    {this.props.children}
                </ReactReduxFirebaseProvider>
            </Provider>
        );
    }
}
export default FirebaseReduxComponent;
