import {
    ACTION_APP_OPS_SIDEMENU_DRAWER_OPEN,
    ACTION_APP_OPS_SIDEMENU_DISABLE_ALL,
    ACTION_APP_OPS_SIDEMENU_PAYMENT_METHODS_ACTIVE,
    ACTION_APP_OPS_SIDEMENU_ACCOUNT_SETTINGS_ACTIVE,
    ACTION_APP_OPS_SIDEMENU_ADD_FIRM_ACTIVE,
    ACTION_APP_OPS_SIDEMENU_THEME_SETTINGS_ACTIVE,
    ACTION_APP_OPS_SET_ONBOARDING_PAGE_ID
} from './type'

export function setDrawerOpen(open) {
    return {
        type: ACTION_APP_OPS_SIDEMENU_DRAWER_OPEN,
        open: open
    }
}

export function disableAll() {
    return {
        type: ACTION_APP_OPS_SIDEMENU_DISABLE_ALL,
        payment_methods: false,
        account_settings: false,
        theme_settings: false,
        add_firm: false
    }
}

export function setPaymentMethods(active) {
    return {
        type: ACTION_APP_OPS_SIDEMENU_PAYMENT_METHODS_ACTIVE,
        payment_methods: active
    }
}

export function setAccountSettings(active) {
    return {
        type: ACTION_APP_OPS_SIDEMENU_ACCOUNT_SETTINGS_ACTIVE,
        account_settings: active
    }
}

export function setThemeSettings(active) {
    return {
        type: ACTION_APP_OPS_SIDEMENU_THEME_SETTINGS_ACTIVE,
        theme_settings: active
    }    
}

export function setAddFirm(active) {
    return {
        type: ACTION_APP_OPS_SIDEMENU_ADD_FIRM_ACTIVE,
        add_firm: active
    }   
}

export function setOnBoardingPageID(id) {
    return {
        type: ACTION_APP_OPS_SET_ONBOARDING_PAGE_ID,
        onboarding_pid: id
    }   
}
