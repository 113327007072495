import React, { Suspense, lazy } from 'react';
import Button from '@material-ui/core/Button';
import { compose } from 'redux'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import strings from '../../translate/strings'

const PaymentMethodsOnBoardingVariant = lazy(() => import('../Payment/PaymentMethodsOnBoardingVariant'));
const ProfileInitialSetup = lazy(() => import('../FirmProfile/ProfileInitialSetup'));
const ProfileLogoOnBoardingVariant = lazy(() => import('../FirmProfile/ProfileLogoOnBoardingVariant'));
const ProfileEditOnBoardingVariant = lazy(() => import('../FirmProfile/ProfileEditOnBoardingVariant'));
const LicenseListOnBoardingVariant = lazy(() => import('../FirmLicenses/LicenseListOnBoardingVariant'));
const LawyerListOnBoardingVariant = lazy(() => import('../FirmLawyers/LawyerListOnBoardingVariant'))
const ThemeSettingsFormOnBoardingVariant = lazy(() => import('../FirmProfile/ThemeSettingsFormOnBoardingVariant'))
const FinishOnBoarding = lazy(() => import('./FinishOnBoarding'))

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 30,
  },
  freeText: {
  	marginLeft: 20
  }
}));

const ColoredLine = ({ color }) => (
    <hr
        style={{
            color: color,
            backgroundColor: color,
            height: 5,
            position: 'absolute',
            top: 70,
            left: 30,
            right: 30
        }}
    />
);

function OnBoardingContent(props) {
    const classes = useStyles();
    // TagManager.dataLayer(tagManagerArgs)

    const { dispatch, onboarding_pid } = props;
    let milestone = null;
    let title = null;
    let subtitle = null;
    let page = null;

	switch(onboarding_pid) {
		case 0: {
			title = strings.onboarding_p1_title //"Add name for lawfirm.";
			subtitle = strings.onboarding_p1_subtitle
			page = (<Suspense fallback={<div>Loading...</div>}>
				<ProfileInitialSetup />
			</Suspense>);
			break;
		}
		case 1: {
			title = strings.onboarding_p2_title //"logo.";
			subtitle = strings.onboarding_p2_subtitle //"";
			page = (<Suspense fallback={<div>Loading...</div>}>
				<ProfileLogoOnBoardingVariant />
			</Suspense>);
			break;
		}
		case 2: {
			title = strings.onboarding_p3_title //"Select theme for your firm";
			subtitle = strings.onboarding_p3_subtitle //"The theme and font selected will be used in the client apps for all lawyers and clients of this firm."
			page = (
			<div>
				<Suspense fallback={<div>Loading...</div>}>
					<ThemeSettingsFormOnBoardingVariant />
				</Suspense>
				<Typography className={classes.freeText} variant="h6" component="h6">
					{strings.onboarding_logo_helper}
				</Typography>
			</div>);
			break;
		}
		case 3: {
			title = strings.onboarding_p4_title //"Add a payment card";
			subtitle = strings.onboarding_p4_subtitle //"This card will be used when purchasing a membership for managed lawyers."
			//remove as requested
			//<Typography className={classes.freeText} variant="h6" component="h6">
			//	{strings.onboarding_card_helper}
			//</Typography>
			page = (<Suspense fallback={<div>Loading...</div>}>
				<PaymentMethodsOnBoardingVariant />
			</Suspense>);
			break;
		}
		case 4: {
			title = strings.onboarding_p5_title //"Purchase memberships";
			subtitle = strings.onboarding_p5_subtitle //"Each membership contains a monthly renewed subscription. You will then be able to allocate lawyers to the memberships you purchase."
			page = (<Suspense fallback={<div>Loading...</div>}>
				<LicenseListOnBoardingVariant />
			</Suspense>);
			break;
		}
		case 5: {
			milestone = strings.onboarding_compulsory_completed_helper;
			title = strings.onboarding_p6_title //"Start adding lawyers";
			subtitle = strings.onboarding_p6_subtitle //"Add lawyers to your firm by providing an email address. Their account will be created and you will be able to edit their profiles too."
			page = (<Suspense fallback={<div>Loading...</div>}>
				<LawyerListOnBoardingVariant />
			</Suspense>);
			break;
		}
		case 6: {
			title = strings.onboarding_p7_title //"Finish lawfirm setup.";
			subtitle = strings.onboarding_p7_subtitle //"";
			page = (<Suspense fallback={<div>Loading...</div>}>
				<ProfileEditOnBoardingVariant />
			</Suspense>);
			break;
		}
		case 7: {
			title = strings.onboarding_p8_title // "Set up complete!";
			subtitle = strings.onboarding_p8_subtitle //"Please continue to the lawfirm." 
			page = (<Suspense fallback={<div>Loading...</div>}>
				<FinishOnBoarding />
			</Suspense>);
			break;
		}
		default:break;
	}

    return (
	    <div className={classes.root}>
			<Typography style={{whiteSpace: 'pre-line'}} variant="h4" color="textPrimary" gutterBottom>
				{milestone}
			</Typography>	    
			<Typography style={{whiteSpace: 'pre-line'}} variant="h4" color="textSecondary" gutterBottom>
				{title}
			</Typography>
			<Typography style={{whiteSpace: 'pre-line'}} variant="h5" component="h2">
				{subtitle}
			</Typography>
			{ page }
		</div>
    )
}

const enhance = compose(
  connect(
      // Map redux state to component props
      ({
          appOps: { onboarding_pid }
      }) => ({
          onboarding_pid
      })
  )
)

export default enhance(OnBoardingContent);
