import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useFirestore, useFirebaseConnect, isLoaded, isEmpty } from 'react-redux-firebase'
import Container from "@material-ui/core/Container";
import { Button } from '@material-ui/core';
import MenuRouter from '../components/MainMenu/Router';
import SideMenu from '../components/SideMenu/SideMenu';
import SideRouter from '../components/SideMenu/SideRouter';
import LoginScreen from './Login/LoginScreen';
import OnBoardingPage from './OnBoarding/OnBoardingPage';
import { firmSelect } from '../redux/action/firmOps';
import firebase from 'firebase/app';
import MyLawyerLogo from '../logo.svg';
import BackgroundDiagonal from '../assets/diagonal.svg';
import { setOnBoardingPageID, setPaymentMethods } from '../redux/action/appOps'

import TagManager from 'react-gtm-module';

const tagManagerArgs = {
    dataLayer: {
        event: 'Onboarding',
        onboarding_step: 'free_trial_payment_method_add_link_opened'
    },
    dataLayerName: 'PageDataLayer'
}

const useStyles = makeStyles(theme => ({
  loginRoot: {
    backgroundColor: '#e9ecef',
    backgroundImage: `url(${BackgroundDiagonal})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: '100% 100%',
    maxWidth: '100%',
    padding: 0
  },
  logoArea: {
    width: '100%',
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '5px 5%',
    boxSizing: 'border-box'
  },
  logo: {
    maxHeight: '35px'
  },
  websiteButton: {
    borderColor: 'rgba(0,0,0,.12)',
    letterSpacing: '0.0892857143em',
    borderRadius: '4px'
  },
  loginContent: {
      maxWidth: '400px',
      margin: '0 auto'
  },
  icon: {
      color: theme.palette.text.secondary,
      marginRight: theme.spacing(2),
  }
}));

function AppContent(props) { //NOTE: removed code for auth providers and email verification check.

    const classes = useStyles();
    const { firebase, dispatch, auth, user_id, firm_managed, onboarding_pid } = props;
    const [loadingIndicator, setLoadingIndicator] = useState(true);
    let firestore = useFirestore();

    let managedFirms = [{
        path: `/user_ops/${user_id}/firm_managed`,
        storeAs: "firm_managed",
    }];

    useFirebaseConnect(managedFirms);

    let appScreen = null;

    useEffect(() => {
        setTimeout(function() {
            setLoadingIndicator(false);
        }, 1000);
        
        let addPaymentMethodQuery = window.location.search.substring(1);
        if (addPaymentMethodQuery == 'addpayment=true') {
            TagManager.dataLayer(tagManagerArgs);
            dispatch(setPaymentMethods(true));
        }
    },[])

    if (isEmpty(auth)) {
        appScreen = (
          <Container className={classes.loginRoot}>
            <div className={classes.logoArea} >
              <img src={MyLawyerLogo} alt="MyLawyer" className={classes.logo}/>
              <Button href="https://www.mylawfirm.app" variant="outlined" color="secondary" className={classes.websiteButton}>MyLawFirm website</Button>
            </div>
            <div className={classes.loginContent}>
                <LoginScreen/>
            </div>
        </Container>)
    } else if (loadingIndicator) {
      appScreen = (<div>Loading, please wait.</div>);
    } else {

        let shouldShowOnBoarding = false;

        if (isLoaded(firm_managed)) {
          if (firm_managed === null) {
            let ref = firebase.ref(`user_ops/${user_id}/firm_managed`).push();
            let generatedFirmId = ref.key;
            dispatch(firmSelect(generatedFirmId));
            firebase.ref(`user_ops/${user_id}/firm_managed/${generatedFirmId}/in_progress`).set(true);
            shouldShowOnBoarding = true;
          } else {
            Object.keys(firm_managed).forEach(function (firmId) {
              let value = firm_managed[firmId];
              if (value.in_progress) {
                // if (value.onboarding_pid) {
                //   dispatch(setOnBoardingPageID(value.onboarding_pid));
                // }
                //check the firm_ops for registration_step
                firestore.collection("firm_ops").doc(firmId).get().then(function(doc) {
                    if (doc.exists) {
                        let data = doc.data();
                        if (data.registration_step) {
                            dispatch(setOnBoardingPageID(data.registration_step));
                            console.log("step:", data.registration_step);
                        }
                    }
                })

                shouldShowOnBoarding = true;
                dispatch(firmSelect(firmId));
                return;
              }
            });
          }
        } else {
          shouldShowOnBoarding = true;
        }

        // let shouldShowOnBoarding = true;

        if (shouldShowOnBoarding) {
          appScreen = (
              <div className="appContent">
                  <div className="appTray fullWidth">
                      <OnBoardingPage />
                  </div>
              </div>
          )
        } else {
          appScreen = (
              <div className="appContent">
                  <div className="appTray left side-menu">
                      <SideMenu/>
                      <SideRouter/>
                  </div>
                  <div className="appTray middle"><MenuRouter /></div>
              </div>
          )          
        }
    }

    return appScreen
}

const enhance = compose(
  connect(
    // Map redux state to component props
    ({
        firebase: { auth, data: { firm_managed } },
        appOps: { onboarding_pid }
    }) => ({
        firebase,
        auth,
        user_id: auth.uid,
        firm_managed,
        onboarding_pid
    })
  ),
)
export default enhance(AppContent);