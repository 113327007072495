import React, { Suspense, lazy } from 'react';
import clsx from 'clsx';
import { compose } from 'redux'
import { connect } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles';
import { Paper, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { disableAll } from '../../redux/action/appOps'

const PaymentMethods = lazy(() => import('../Payment/PaymentMethods'));
const AccountSettingsForm = lazy(() => import('../FirmAdmin/AccountSettingsForm'));
const ThemeSettingsForm = lazy(() => import('../FirmProfile/ThemeSettingsForm'));
// const ProfileInitialSetup = lazy(() => import('../FirmProfile/ProfileInitialSetup'));

const paneLibrary = [
    {
        id: "payment_methods", label: "Payment methods"
    },
    {
        id: "account_settings", label: "Account settings"
    },
    {
        id: "theme_settings", label: "Theme settings"
    },
    {
        id: "add_firm", label: "Add Firm"
    }
];

const useStyles = makeStyles(theme => ({
    root: {
        position: 'absolute',
        top: '0px',
        height: '100%',
        top: '-10px',
        marginLeft: '60px',
        zIndex: '15'
    },
    rootOpen: {
        marginLeft: '207px'
    },
    rootClosed: {

    },
    blade: {
        width: 320,
        height: '100%',
        'background-color': theme.mylawyer.background.light,
        position: 'absolute',
        zIndex: 5,
        padding: '5px 5px 5px 10px'
    },
    closeButtonArea: {
        display: 'flex',
        justifyContent: 'flex-end',
        position: 'absolute',
        width: '100%',
        right: '5px'
    },
    headerArea: {
        width: '100%',
        backgroundColor: theme.mylawyer.color.midGrey,
        padding: '10px 20px',
        boxSizing: 'border-box',
        textTransform: 'uppercase',
        marginBottom: '20px',
        fontWeight: 'bold'
    },
    instructionArea: {
        marginBottom: '30px',
        padding: '0px 20px'
    }
}));

function SideRouter(props) {

    const classes = useStyles();
    const { dispatch, side_menu } = props;

    //Create panes lazily
    function _createPane(type) {
        switch(type) {
            case 'payment_methods': {
                return (<PaymentMethods key="payment_methods"/>)
            }
            case 'account_settings': {
                return (<AccountSettingsForm key="account_settings"/>)
            }
            case 'theme_settings': {
                return (<ThemeSettingsForm key="theme_settings"/>)
            }
            // case 'add_firm': {
            //     return (<ProfileInitialSetup key="add_firm"/>)
            // }
        }
        return null;
    }

    function _createHeader(type) {
        switch(type) {
            case 'payment_methods': {
                return {
                    title: 'Payment method',
                    instruction: 'Manage payment method to use with license subscriptions.'
                }
            }
            case 'account_settings': {
                return {
                    title: 'Manage firm account',
                    instruction: 'Manage firm administrators.',
                }
            }
            case 'theme_settings': {
                return {
                    title: 'Manage firm theme',
                    instruction: 'Add or edit firm theme. The selected theme will be applied to the client apps.',
                    bladeWidth: 650
                }
            }
            case 'add_firm': {
                return {
                    title: 'Add Another Law Firm',
                    instruction: 'Start adding a new firm to manage by adding a name and an address. You will be able complete the set up later.',
                    bladeWidth: 650
                }
            }
        }
        return null;
    }

    function _handleCloseClick() {
        dispatch(disableAll());
    }

    let bladeWidth=320;

    let displayItems = paneLibrary.reduce((acc, cur, idx) => { 
        if (side_menu && cur.id in side_menu && side_menu[cur.id]) {
            let text = _createHeader(cur.id);
            if (text?.title) {
                acc.push(<div className={classes.headerArea} key={cur.id + '_title'}><Typography component="h3">{text.title}</Typography></div>)
            }
            if (text?.instruction) {
                acc.push(<div className={classes.instructionArea} key={cur.id + '_instruct'}><Typography>{text.instruction}</Typography></div>)
            }
            if (text?.bladeWidth) {
                bladeWidth = text.bladeWidth
            } else {
                bladeWidth = 320;
            }
            let pane = _createPane(cur.id);
            acc.push(pane)
        } 
        return acc;
    }, [])

    let paper = null;
    if (displayItems?.length > 0) {
        paper = (
            <Paper elevation={1} variant="outlined" className={classes.blade} style={{width: bladeWidth}}>
                <div className={classes.closeButtonArea}>
                    <IconButton aria-label="close" onClick={_handleCloseClick}>
                        <CloseIcon/>
                    </IconButton>
                </div>
                {displayItems}
            </Paper>
        )
    }

    return (
        <div className={clsx(classes.root, {
            [classes.rootOpen]: side_menu?.open,
            [classes.rootClosed]: !side_menu?.open,
          })}>
            <Suspense fallback={<div>Loading...</div>}>
                {paper}
            </Suspense>
        </div>
    )
}

const enhance = compose(
    connect(
        // Map redux state to component props
        ({
            appOps: { side_menu }
        }) => ({
            side_menu
        })
    )
)

export default enhance(SideRouter);