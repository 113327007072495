import React, { useState } from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button';
import { useFirebase, useFirestore } from 'react-redux-firebase'
import { setOnBoardingPageID } from '../../redux/action/appOps'
import { makeStyles } from '@material-ui/core/styles';
import firebase from 'firebase/app';
import { braintreeVaultList, licenseAdd } from '../../util/braintree';
import { addFoundingAdminMembership }  from '../../util/invite';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Translate, strings } from '../../translate'
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles(theme => ({
    nextButton: {
        marginTop: 30,
        width: '100%',
    }
}));

function FooterComponent(props) {
    const { dispatch, onboarding_pid, user_id, firm_id } = props;
    const classes = useStyles();

    const [paymentMethodNeededDialogOpen, setPaymentMethodNeededDialogOpen] = useState(false);
    const [inProgress, setInProgress] = useState(false);

    const firebase = useFirebase();
    const firestore = useFirestore();
    
    function incrementPageId() {
        // firebase.ref(`user_ops/${user_id}/firm_managed/${firm_id}/onboarding_pid`).set(++newPageId);
        firestore.collection("firm_ops").doc(firm_id).set({
            registration_step: onboarding_pid + 1
        }, { merge: true } )
        .then(() => {
            dispatch(setOnBoardingPageID(onboarding_pid + 1))
        })
    }

    function checkPaymentAndIncrementPageId() {

      setInProgress(true);

      braintreeVaultList(firm_id).then(customer => {
          if (customer.paymentMethods && customer.paymentMethods.length > 0) {
            // has payment method and can go to next screen
            //new. purchase a membership for administrator

            licenseAdd(firm_id, 1, (success) => {
                addFoundingAdminMembership(firm_id, (info) => {
                    incrementPageId();
                    setInProgress(false);
                }, (error) => {
                    //TBD: if errored, what to present to user.
                    incrementPageId();
                    setInProgress(false);
                });
            }, (error) => {
                //TBD: if errored, what to present to user.
                incrementPageId();
                setInProgress(false);
            })

          } else {
            setInProgress(false);
            setPaymentMethodNeededDialogOpen(true);
          }
      });
    }

    function finishOnboarding() {
      firebase.ref(`user_ops/${user_id}/firm_managed/${firm_id}`).set(true); //this removes the in_progress that puts the user in onboarding pages.
      window.location.reload();
    }

    switch(onboarding_pid) {
      case 0:
      case 1:
      case 2: {
        return (<div />);
      }
      case 3: {
        // "Add a payment card" - next button check if user has a payment card
        return (
            <React.Fragment>
              <Dialog
                  className={classes.mediaDialog}
                  open={paymentMethodNeededDialogOpen}
                  onClose={() => setPaymentMethodNeededDialogOpen(false)}>
                <DialogTitle id="form-dialog-title">
                    <Translate value={strings.lawfirm_payment_method_needed_onboarding} />
                </DialogTitle>
                  <DialogActions>
                      <Button onClick={() => setPaymentMethodNeededDialogOpen(false)} color="primary">
                          <Translate value={strings.form_button_ok} />
                      </Button>
                  </DialogActions>
              </Dialog>
              {inProgress && <LinearProgress />}
              <Button variant="contained" color="primary" className={classes.nextButton} onClick={checkPaymentAndIncrementPageId}>
                  Next
              </Button>
            </React.Fragment>
        )
      }
      case 4: {
        //"Purchase memberships" - normal next button. no checks or check at least one license purchased?
        return (
            <Button variant="contained" color="primary" className={classes.nextButton} onClick={incrementPageId}>
                Next
            </Button>
        )
      }
      case 5: {
        //"Start adding lawyers" - normal next button. no checks or check at least one lawyer allocated?
        //added skip button as requested to make it very clear they can skip. Works exactly like the next button
        return (
          <div>
            <Button variant="contained" color="primary" className={classes.nextButton} onClick={incrementPageId}>
                Next
            </Button>
            <Button variant="contained" color="primary" className={classes.nextButton} onClick={incrementPageId}>
                Skip
            </Button>
          </div>
        )
      }
      case 6: {
        // "logo" - no next button, ProfileEditOnBoardingVariant's save button to be 'save and next'
        // return (<div />);
        return (
            <Button variant="contained" color="primary" className={classes.nextButton} onClick={incrementPageId}>
                Skip
            </Button>
        )
      }
      case 7: {
        return (
            <Button variant="contained" color="primary" className={classes.nextButton} onClick={finishOnboarding}>
                FINISH
            </Button>
        )
      }
      default: return <React.Fragement />;
    }

}

const enhance = compose(
  connect(
      // Map redux state to component props
      ({
          firebase: { auth: { uid } },
          appOps: { onboarding_pid },
          firmOps: { firm_id }
      }) => ({
          user_id: uid,
          onboarding_pid,
          firm_id
      })
  )
)

export default enhance(FooterComponent);