import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

const useStyles = makeStyles(theme => ({
  headerWrapper: {
    width: '100%',
  },
  stepItem: {
    width: 50,
    height: 50
  }
}));

function HeaderComponent(props) {
    const classes = useStyles();

    const { onboarding_pid } = props;

    const headerBuilder = (
      <Stepper activeStep={onboarding_pid}>
        {[0, 1, 2, 3, 4, 5, 6, 7].map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps} >
              <StepLabel StepIconProps={{
                    classes: { root: classes.stepItem }
                  }} {...labelProps}></StepLabel>
            </Step>
          );
        })}
      </Stepper>
    );

    return (
      <React.Fragment>

      <div className={classes.headerWrapper}>
        {headerBuilder}
      </div>        

      </React.Fragment>
    )
}

const enhance = compose(
  connect(
      // Map redux state to component props
      ({
          appOps: { onboarding_pid }
      }) => ({
          onboarding_pid
      })
  )
)

export default enhance(HeaderComponent);
