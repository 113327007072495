import firebase from 'firebase/app'
import 'firebase/auth'

export const uploadUserProfilePicture = (data) => {
    var formData = new FormData();
    formData.append('file', data);
    return firebase.auth().currentUser.getIdToken(true).then(function (idToken) {
        return fetch(process.env.REACT_APP_FUNCTION_URI + `/storage/profile?token=` + idToken, {
            method: 'POST',
            body: formData
        })
        .then((response) => {
            return true;
        })
        .catch((error) => { console.error(error); throw error; });
    });
}

export const uploadFirmProfilePicture = (firmId, data) => {
    var formData = new FormData();
    formData.append('file', data);
    return firebase.auth().currentUser.getIdToken(true).then(function (idToken) {
        return fetch(process.env.REACT_APP_FUNCTION_URI + `/storage/firm/${firmId}?token=` + idToken, {
            method: 'POST',
            body: formData
        })
        .then((response) => {
            return true;
        })
        .catch((error) => { console.error(error); throw error; });
    });
}

export const uploadFirmLawyerProfilePicture = (lawyerUid, data) => {
    var formData = new FormData();
    formData.append('file', data);
    return firebase.auth().currentUser.getIdToken(true).then(function (idToken) {
        return fetch(process.env.REACT_APP_FUNCTION_URI + `/storage/firmlawyer/${lawyerUid}?token=` + idToken, {
            method: 'POST',
            body: formData
        })
        .then((response) => {
            return true;
        })
        .catch((error) => { console.error(error); throw error; });
    });
}