import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AssistantIcon from '@material-ui/icons/Assistant';
import BusinessIcon from '@material-ui/icons/Business';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import BookmarksIcon from '@material-ui/icons/Bookmarks';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import Checkbox from '@material-ui/core/Checkbox';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useFirebaseConnect, useFirestoreConnect } from 'react-redux-firebase'
import { makeStyles } from '@material-ui/core/styles';
import { firmSelect } from '../../redux/action/firmOps';
import { braintreeVaultList } from '../../util/braintree'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  button: {
    width: '100%'
  },
  buttonAssistantIcon: {
    'position': 'absolute',
    'top': '5px',
    'right': '-20px',
    'color': 'white'
  }
}));

/**
 * Provides a LawfirmProfileCompletionWizard
 * @param {*} props
 */
function LawfirmProfileCompletionWizard(props) {
    const classes = useStyles();
    const { dispatch, user_id, firm_managed, firm_data, firm_id, firm_allocated_lawyers_ids, firm_pending_lawyers_ids, firm_licenses_count } = props;

    const [open, setOpen] = useState(false); //by default open.

    const [paymentMethodFlag, setPaymentMethodFlag] = useState(false);

    braintreeVaultList(firm_id).then(customer => {
        if (customer.paymentMethods && customer.paymentMethods.length > 0) {
            setPaymentMethodFlag(true)
        } else {
            setPaymentMethodFlag(false)
        }
    });

    let firmName = ''; //cf useState
    let firmDetailsSet = false;

    // let managedFirmsData = [{
    //     path: `/firm_private/${firm_id}`,
    //     storeAs: `firm_data/${firm_id}`
    // }];

    // useFirebaseConnect(managedFirmsData);

    let managedFirms = [{
        path: `/user_ops/${user_id}/firm_managed`,
        storeAs: "firm_managed",
        queryParams: ['orderByChild=created']
    }];

    const managedFirmsData = Object.keys(firm_managed || {})?.map((firm_id) => {
        return {
            path: `/firm_private/${firm_id}`,
            storeAs: `firm_data/${firm_id}`
        }
    });

    if (managedFirmsData) {
        managedFirms = managedFirms.concat(managedFirmsData)
    }

    useFirebaseConnect(managedFirms);

    let firmLawyerLicensesData = [];
    if (firm_id && firm_id != "") {
        firmLawyerLicensesData = [{
            collection: 'firm_ops',
            doc: firm_id,
            subcollections: [{ collection: 'seat_allocated' }],
            storeAs: "firm_allocated_lawyers_ids"
        },{
            collection: 'firm_ops',
            doc: firm_id,
            subcollections: [{ collection: 'seat_pending' }],
            storeAs: "firm_pending_lawyers_ids"
        },{
            collection: 'firm_billing_receipt',
            doc: firm_id,
            subcollections: [{ collection: 'licenses' }],
            storeAs: 'firm_licenses_count'
        }];
    }
    useFirestoreConnect(firmLawyerLicensesData);

    let allocatedLawyerCount = 0;
    let pendingLawyerCount = 0;
    let licenseCount = 0;

    if (firm_allocated_lawyers_ids && firm_id in firm_allocated_lawyers_ids && firm_allocated_lawyers_ids[firm_id] === null) {
        allocatedLawyerCount = 0;
    } else {
        allocatedLawyerCount = Object.keys(firm_allocated_lawyers_ids || {})?.length;
    }

    if (firm_pending_lawyers_ids && firm_id in firm_pending_lawyers_ids && firm_pending_lawyers_ids[firm_id] === null) {
        pendingLawyerCount = 0;
    } else {
        pendingLawyerCount = Object.keys(firm_pending_lawyers_ids || {})?.length;
    }

    if (firm_licenses_count && firm_id in firm_licenses_count && firm_licenses_count[firm_id] === null) {
        licenseCount = 0;
    } else {
        licenseCount = Object.keys(firm_licenses_count || {})?.length;
    }

    //get license and seat allocated and seat pending from firestore

    if (firm_id && firm_id != '') {
        firmName = firm_data?.[firm_id]?.name;

        //check for firm details completion
        let description = firm_data?.[firm_id]?.description;
        let specialisms = firm_data?.[firm_id]?.specialisms;
        let locations = firm_data?.[firm_id]?.locations;
        let address = firm_data?.[firm_id]?.address;
        if (description && description != '' && address && address != '' && specialisms && Object.keys(specialisms || {}).length > 0 && locations && Object.keys(locations || {}).length > 0) {
            firmDetailsSet = true;    
        } else {
            firmDetailsSet = false;    
        }
    } else {
        //logic taken from lawfirm select dropdown
        const managedFirmIds = Object.keys(firm_managed || {})
        const defaultFirmId = managedFirmIds[0]
        dispatch(firmSelect(defaultFirmId));
    }

    const handleClick = () => {
        setOpen(!open);
    };

  return (
    <div className={classes.root}>
      <Button className={classes.button} variant="contained" color="primary" onClick={handleClick}>
        {firmName}
        <ListItemIcon className={classes.buttonAssistantIcon}><AssistantIcon /></ListItemIcon>
      </Button>
      { open ? 
        <List>
            {[
              {
                text: 'Add payment details',
                key: 'add_payment_details',
                icon: (<CreditCardIcon/>),
                completed: paymentMethodFlag
              },{
                text: 'Add firm details',
                key: 'add_firm_details',
                icon: (<BusinessIcon/>),
                completed: firmDetailsSet
              },{
                text: 'Add lawyers',
                key: 'add_lawyers',
                icon: (<PersonAddIcon/>),
                completed: allocatedLawyerCount + pendingLawyerCount > 0
              },{
                text: 'Add memberships',
                key: 'add_memberships',
                icon: (<BookmarksIcon/>),
                completed: licenseCount > 0
              }].map((item, index) => (
              <ListItem button key={item.key} data-id={item.key}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
                <Checkbox disabled checked={item.completed} />
              </ListItem>
            ))}
          </List> 
          : <React.Fragment /> 
      }
    </div>
  );
}

const enhance = compose(
  connect(
    ({
        firebase: { auth: { uid }, data: { firm_managed, firm_data } },
        firestore: { data: { firm_allocated_lawyers_ids, firm_pending_lawyers_ids, firm_licenses_count }},
        firmOps: { firm_id }
    }) => ({
        user_id: uid,
        firm_managed,
        firm_data,
        firm_id,
        firm_allocated_lawyers_ids,
        firm_pending_lawyers_ids,
        firm_licenses_count
    })
  ),
)


export default enhance(LawfirmProfileCompletionWizard);


