import {
    ACTION_PROFILE_USER_SELECT
} from "../action/type";


export default function userProfileReducer(state = {}, action) {
    switch (action.type) {
        case ACTION_PROFILE_USER_SELECT: {
            return Object.assign({}, state, {
                selected_user_id: action.user_id
            });
        }
        default:
            return state;
    }
}
