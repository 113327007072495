import {
    ACTION_APP_OPS_SIDEMENU_DRAWER_OPEN,
    ACTION_APP_OPS_SIDEMENU_DISABLE_ALL,
    ACTION_APP_OPS_SIDEMENU_PAYMENT_METHODS_ACTIVE,
    ACTION_APP_OPS_SIDEMENU_ACCOUNT_SETTINGS_ACTIVE,
    ACTION_APP_OPS_SIDEMENU_THEME_SETTINGS_ACTIVE,
    ACTION_APP_OPS_SIDEMENU_ADD_FIRM_ACTIVE,
    ACTION_APP_OPS_SET_ONBOARDING_PAGE_ID
} from "../action/type";


const initialState = {
    onboarding_pid: 0,
};


export default function appOpsReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_APP_OPS_SIDEMENU_DRAWER_OPEN: {
            return Object.assign({}, state, {
                side_menu: Object.assign({}, state?.side_menu, {
                    open: action.open
                })
            });
        }
        case ACTION_APP_OPS_SIDEMENU_DISABLE_ALL: {
            return Object.assign({}, state, {
                side_menu: Object.assign({}, state?.side_menu, {
                    payment_methods: false,
                    account_settings: false,
                    theme_settings: false,
                    add_firm: false
                })
            });
        }
        case ACTION_APP_OPS_SIDEMENU_PAYMENT_METHODS_ACTIVE: {
            return Object.assign({}, state, {
                side_menu: Object.assign({}, state?.side_menu, {
                    payment_methods: action.payment_methods
                })
            });
        }
        case ACTION_APP_OPS_SIDEMENU_ACCOUNT_SETTINGS_ACTIVE: {
            return Object.assign({}, state, {
                side_menu: Object.assign({}, state?.side_menu, {
                    account_settings: action.account_settings
                })
            });
        }
        case ACTION_APP_OPS_SIDEMENU_THEME_SETTINGS_ACTIVE: {
            return Object.assign({}, state, {
                side_menu: Object.assign({}, state?.side_menu, {
                    theme_settings: action.theme_settings
                })
            });            
        }
        case ACTION_APP_OPS_SIDEMENU_ADD_FIRM_ACTIVE: {
            return Object.assign({}, state, {
                side_menu: Object.assign({}, state?.side_menu, {
                    add_firm: action.add_firm
                })
            });            
        }
        case ACTION_APP_OPS_SET_ONBOARDING_PAGE_ID: {
            return {
                ...state,
                onboarding_pid: action.onboarding_pid
            } 
        }
        default:
            return state;
    }
}
