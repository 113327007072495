import React from 'react';
import FirebaseProvider from './redux/provider';
import { createMuiTheme, StylesProvider, ThemeProvider } from '@material-ui/core/styles';
import AppContent from './components/AppContent';
import ErrorBoundary from './components/ErrorBoundaries/ErrorBoundary';
import myLawyerTheme from './theme'
import { hot } from 'react-hot-loader/root'

const muiTheme = createMuiTheme(myLawyerTheme);

function App() {

  return (
    <FirebaseProvider>
      <StylesProvider>
        <ThemeProvider theme={muiTheme}>
          <div className="mainApp">
            <ErrorBoundary>
              <AppContent />
            </ErrorBoundary>
          </div>
        </ThemeProvider>
      </StylesProvider>
    </FirebaseProvider>
  );
}

export default process.env.NODE_ENV === "development" ? hot(App) : App
