export default {
    und: {
        form_button_cancel: "Cancel",
        form_button_save: "Save",
        form_button_send: "Send",
        form_button_delete: "Delete",
        form_button_confirm: "Confirm",
        form_button_ok: "OK",
        main_menu_home: "HOME",
        main_menu_firm_page: "myLawFirm",
        main_menu_firm_seats: " myMemberships",
        main_menu_firm_lawyers: "myLawyer Profiles",
        login: "Login",
        lawfirm_lawyers: "Lawyers in this Firm",
        lawfirm_lawyer_invite: "Add a member",
        lawfirm_lawyers_unallocated_select: "Select one lawyer to allocate",
        lawfirm_administrators: "Administrators",
        lawfirm_theme_palette_select: "Select a theme for your platform",
        lawfirm_theme_font_select: "Select a font for your platform",
        lawfirm_payment_method_needed: "Payment methods are needed. Please add using the payment methods button on the left",
        lawfirm_payment_method_needed_onboarding: "Please add a payment method to proceed.",
        lawfirm_licenses: "Memberships",
        lawfirm_license_allocated: "Seat allocated",
        lawfirm_license_available: "Not allocated. Not published",
        lawfirm_select_prompt: "Select a firm to get started",
        lawfirm_license_cancel_confirm: "Please confirm cancelation of this single membership. This single membership will be deleted immediately and it can no longer be used for lawyer allocation. Any other memberships will remain active.",
        lawfirm_license_purchase_confirm: "Please confirm purchase of this single membership. This will renew monthly.",
        lawfirm_license_batch_purchase_confirm: "Please select the number of memberships and confirm purchase. Each individual membership will renew monthly.",
        lawfirm_lawyer_password_reset: "Reset password",
        profile_field_label_profile_description: "About me",
        profile_field_label_profile_description_work: "My Work",
        profile_field_label_profile_description_client: "What my clients say",
        profile_field_label_profile_description_professional: "Professional & academic",
        manage_lawyer_help_title: "Hints and Tips",
        manage_lawyer_help_subtitle: "LAWYER PROFILES",
        manage_lawyer_help_text: "The lawyers’ profiles are searchable and are essential for a professional image of your law firm. Only lawyers with memberships are published to the client website. Changes you make in the profiles here will override and profile data that exist in the platform. If your lawyers modify their profiles on the platform their changes will override the entries on the console.",
        license_help_title: "Hints and Tips",
        license_help_subtitle: "MANAGING SEATS",
        license_help_text: "You are encouraged to add memberships for all your lawyers, legal assistants and secretaries in your law firm. This is the best way to take full advantage of the platform. All members that you add here and all the clients that are invited to the platform by your lawyers will be part of your law firm branded ecosystem and will allow you to communicate with them safely and seamlessly.",
        firm_profile_help_title: "Hints and Tips",
        firm_profile_help_subtitle: "Your LawFirm profile",
        firm_profile_help_text: "Please make sure that the profile for your law firm is complete and has full information on your firm’s practice. This is essential as the profile is searchable via the platform and the client website so by maintaining a complete profile you maximize the probability that clients will be able to reach you.",
        firm_location_help_text: "Please list the city where your firm is located. In addition if you practice in other locations please add those as well.",
        firm_profile_setup_text: "Add your law firm details below,",
        onboarding_p1_title: "Please enter the name of your Law Firm",
        onboarding_p1_subtitle: "",
        onboarding_p2_title: "Upload Law Firm Logo",
        onboarding_p2_subtitle: "Click on the button below to select the file that contains your firm’s logo and upload it to your platform. If you do not have a firm logo you can create one now by pressing the Logo Creation button.",
        onboarding_logo_helper: "If you would like a different theme to match your company’s brand please email us at support@mylawyernetwork.com and we will include this in the next update of the platform.",
        onboarding_p3_title: "Select a theme for your Law Firm",
        onboarding_p3_subtitle: `The color theme and font selected will appear in the platform: Web app, iOS and Android apps. You may modify your choice at any time.

If you do not wish to customize your platform then your platform will display the default color theme of the myLawFirm app.`,
        onboarding_p4_title: "Please enter the following details from your credit card.",
        onboarding_p4_subtitle: "Your card will not be charged prior to the end of this trial period. If you do not wish to continue after the expiration of the trial period then please email us on contact@mylawyernetwork.com to cancel your registration. Your credit will not be charged and your credit card details will be erased from the system. You can cancel your registration with us at any time.",
        onboarding_p5_title: "Add memberships",
        onboarding_p5_subtitle: "Please click on the following button and type in the number of lawyers in your firm that you want to become members of the new platform.",
        onboarding_compulsory_completed_helper: "Your firm is now registered for the free trial. The following two screens are optional.",
        onboarding_p6_title: "Add the firm’s members",
        onboarding_p6_subtitle: `You are now the first member of your firm’s customized platform. 

You can now invite other members of your firm to join by clicking the button below and typing in their email addresses. 

People that are invited will receive an email with instructions on how to start using the customized platform. You may also add a personal message to them by typing in the message below. 

You may skip this step and invite the other members of your firm at a later date.`,
        onboarding_p7_title: "Finish lawfirm setup.",
        onboarding_p7_subtitle: "",
        onboarding_p8_title: "Your firm is now registered for the free trial. To learn more about your customized plarform go to www.mylawfirm.app.",
        onboarding_p8_subtitle: `Your setup is now complete. Your customized platform can now be used. You and your members can now access your law firm's network via the free version of the myLawyer app for iOS, Android or via the web app. In addition you now have access to the myLawFirm console. This console will allow you to modify your firms details, add new members and edit their full profiles. 

For web app access and for more information please visit www.mylawyernetwork.com`
    },
    fr: {
        main_menu_home: "ACCUEIL",
    }
}
