import {
    ACTION_LAWFIRM_SELECT,
    ACTION_LAWFIRM_LAWYER_SELECT,
    ACTION_LAWFIRM_LICENSE_LAWYER_SELECT,
    ACTION_LAWFIRM_LAWYER_MORE,
    ACTION_LAWFIRM_UNALLOCATED_LAWYER_MORE,
    ACTION_LAWFIRM_LICENSE_MORE
} from '../action/type'

const initialState = {
    firm_id: '',
    lawyer_page_size: 5,
    unallocated_lawyer_page_size: 5,
    license_page_size: 5,
    // lawyer_uid: '',
    // license_lawyer_uid: ''
};

export default function firmOpsReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_LAWFIRM_SELECT:
            // return {
            //     ...state,
            //     firm_id: action.firm_id
            // }
            //when the firm id has changed, reset all from firmOps
            return Object.assign({}, initialState, {
                firm_id: action.firm_id,
            });
        case ACTION_LAWFIRM_LAWYER_SELECT: {
            return Object.assign({}, state, {
                lawyer_uid: action.lawyer_uid,
            });
        }
        case ACTION_LAWFIRM_LICENSE_LAWYER_SELECT: {
            return Object.assign({}, state, {
                license_lawyer_uid: action.license_lawyer_uid,
            });        
        }
        case ACTION_LAWFIRM_LAWYER_MORE: {
            return Object.assign({}, state, {
                lawyer_page_size: state.lawyer_page_size + 5
            });
        }
        case ACTION_LAWFIRM_UNALLOCATED_LAWYER_MORE: {
            return Object.assign({}, state, {
                unallocated_lawyer_page_size: state.unallocated_lawyer_page_size + 5
            });            
        }
        case ACTION_LAWFIRM_LICENSE_MORE: {
            return Object.assign({}, state, {
                license_page_size: state.license_page_size + 5
            }); 
        }
        default:
            return state;
    }
}
