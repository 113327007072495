import React, { useEffect, useState }  from 'react';
import { v4 as uuidv4 } from 'uuid';
import { compose } from 'redux'
import { connect } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import { convertSVGToImage } from '../../util/imageGenerator'
import { uploadFirmProfilePicture } from '../../util/imageUpload'
import StyleTextInput from './StyleTextInput';
import LogoSvg from './LogoSvg';
import { Grid, Typography, LinearProgress } from '@material-ui/core';
import { SketchPicker } from 'react-color';


const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    button: {
      marginRight: theme.spacing(1),
    },
    stepperButtons: {
        position: 'absolute',
        top: 10,
        right: 0,
        display: 'flex',
        justifyContent: 'flex-end'
    },
    stepArea: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      height: 300
    },
    title: {
        marginBottom: 30
    }
  }));


function LogoEditorDialog(props) {
    const classes = useStyles();

    const {firm_id, open, onComplete} = props;

    const [logoWords, setLogoWords] = useState({})
    const [dialogOpen, setDialogOpen] = useState(false)
    const [progress, setProgress] = useState(false)
    const [backgroundColor, setBackgroundColor] = useState('#ffffff');

    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const steps = getSteps();

    let svgRef =  React.createRef();

    useEffect(() => {
        if (open) {
            setDialogOpen(true)
        }
    }, [open])
    
    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const _handleNextStep = () => {
        if (activeStep === steps.length - 1) {
            sendSvg();
            return;
        }
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
          newSkipped = new Set(newSkipped.values());
          newSkipped.delete(activeStep);
        }
    
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };
    
    const _handleBackStep = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const sendSvg = (e) => {
        let svg = new XMLSerializer().serializeToString(svgRef.current);
        // console.log(backgroundColor);
        setProgress(true);
        const svgBBox = svgRef.current.getBoundingClientRect();
        convertSVGToImage(svg, svgBBox.width, svgBBox.height, backgroundColor).then((result) => {
            // console.log(result);
            uploadFirmProfilePicture(firm_id, result['image']).then((response) => {
                setDialogOpen(false);
                setProgress(false)
                onComplete();
            }).catch((e) => {
                //@todo tell the user
                setProgress(false)
                setDialogOpen(false);
            })
            
        }).catch((e) => {
            //@todo tell the user
            setDialogOpen(false);
        })
    }

    const mergeWordObject = (id, key, value) => {
        let o = {};
        if (logoWords.hasOwnProperty(id)) {
            o = logoWords[id];
        }
        o[key] = value;
        //Check we have a sort order
        if (!o.hasOwnProperty('sort')) {
            o['sort'] = Object.keys(logoWords).length;
        }
        return o;
    }

    const _handleWordChange = (id, text) => {
        let o = mergeWordObject(id, 'word', text);
        setLogoWords({...logoWords, ...{[id]: o }});
    }

    const _handleStyleChange = (id, style) => {
        let o;
        // console.log(id, style);
        if (style.hasOwnProperty('color')) {
            o = mergeWordObject(id, 'color', style.color);
        }
        if (style.hasOwnProperty('font')) {
            o = mergeWordObject(id, 'font', style.font);
        }
        setLogoWords({...logoWords, ...{[id]: o }});
    }
    const _handleResetStep = () => {
        setActiveStep(0);
    };

    const _handleColorChange = (color) => {
        setBackgroundColor(color.hex);
      };

    const _handleClose = () => {
        setDialogOpen(false);
        onComplete();
    }

    //Multiple boxes for logo words
    let logoWordInputs = [];

    for (let [key, word] of Object.entries(logoWords)) {
        logoWordInputs.push(<StyleTextInput key={key} id={key} text={word.word} lineNr={word.sort + 1} onTextChange={_handleWordChange} onStylingChange={_handleStyleChange}/>)
    }
    //Add an additional one

    if (logoWordInputs.length <= 1) {
        var id = uuidv4();
        logoWordInputs.push(<StyleTextInput key={id} id={id} value="" lineNr={logoWordInputs.length + 1} onTextChange={_handleWordChange} onStylingChange={_handleStyleChange}/>)
    }

    function getSteps() {
        return ['Background color', 'Logo text & colors', 'Preview logo'];
    }

    return (
            <Dialog
                fullWidth={true}
                maxWidth={true}
                open={dialogOpen}
                onClose={_handleClose}
                aria-labelledby="logo-editor-dialog-title"
            >
                <DialogTitle id="logo-editor-dialog-title">Create a logo</DialogTitle>
                <DialogContent>
                    {progress && <LinearProgress/>}
                    <DialogContentText>
                        You can create a simple text based logo with fonts and colors.
                    </DialogContentText>
                    <Stepper activeStep={activeStep}>
                        {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                        })}
                    </Stepper>
                    <div>
                        <div className={classes.stepArea}>
                            {activeStep == 0 && 
                                <Grid container>
                                    <Grid item sm={12}>
                                        <SketchPicker disableAlpha={true} color={backgroundColor} onChangeComplete={ _handleColorChange }/>
                                    </Grid>
                                </Grid>
                            }
                            {activeStep == 1 && 
                                <Grid container>
                                    <Grid item sm={12}>
                                        <Typography className={classes.title}>Enter up to two word groups in your logo (scroll to see second group)</Typography>
                                        {logoWordInputs}
                                    </Grid>
                                </Grid>
                            }
                            {activeStep == 2 && 
                                <Grid container>
                                    <Grid item sm={12}>
                                        <Typography>Your logo</Typography>
                                        <div className="preview--area">
                                            <LogoSvg svgRef={svgRef} lines={logoWords} backgroundColor={backgroundColor}/>
                                        </div>
                                    </Grid>
                                </Grid>
                            }
                        </div>
                        <div className={classes.stepperButtons}>
                            <Button disabled={activeStep === 0} onClick={_handleBackStep} className={classes.button}>
                                Back
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={_handleNextStep}
                                className={classes.button}
                                disabled={!(activeStep == 0 ? backgroundColor != '' : activeStep == 1 ? Object.keys(logoWords).length > 0 : backgroundColor && Object.keys(logoWords).length > 0 )}
                            >
                                {activeStep === steps.length - 1 ? 'Finish & Upload' : 'Next'}
                                
                            </Button>
                        </div>
                    </div>
                    
                </DialogContent>
                <DialogActions>
                <Button onClick={_handleClose} color="primary">
                    Close
                </Button>
                </DialogActions>
            </Dialog>
    )
}

const enhance = compose(
    connect(
        ({
            firmOps: { firm_id },
        }) => ({
            firm_id
        })
    )
)

export default enhance(LogoEditorDialog);