import firebase from 'firebase/app'
import 'firebase/auth'

export const resetNewPassword = (newPass, targetUid, onSuccess, onError) => {
	const currentUser = firebase.auth().currentUser;
	if (!currentUser) {
		return Promise.reject("no user");
	}
	return currentUser.getIdToken(true).then(function(idToken) {
		return fetch(process.env.REACT_APP_FUNCTION_URI + '/firm_admin/user/reset-password', { 
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				'firebaseToken': idToken,
				'newPass': newPass,
				'targetUid': targetUid
			})
		}).then((response) => {
			if (!response.ok) {
				onError();
			} else {
				onSuccess();
			}
		}).catch((error) => { 
			onError();
			console.error(error); 
			throw error; 
		});
	});
}

export const sendResetPasswordLink = (targetUid, firmId, firmName, onSuccess, onError) => {
	const currentUser = firebase.auth().currentUser;
	if (!currentUser) {
		return Promise.reject("no user");
	}
	return currentUser.getIdToken(true).then(function(idToken) {
		return fetch(process.env.REACT_APP_FUNCTION_URI + '/firm_admin/user/send-reset-password-link', { 
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				'firebaseToken': idToken,
				'targetUid': targetUid,
				'firmId': firmId,
				'firmName': firmName
			})
		}).then((response) => {
			if (!response.ok) {
				onError();
			} else {
				onSuccess();
			}
		}).catch((error) => { 
			onError();
			console.error(error); 
			throw error; 
		});
	});
}

export const removeLawyerFromFirm = (targetUid, firmId, onSuccess, onError) => {
	const currentUser = firebase.auth().currentUser;
	if (!currentUser) {
		return Promise.reject("no user");
	}
	return currentUser.getIdToken(true).then(function(idToken) {
		return fetch(process.env.REACT_APP_FUNCTION_URI + '/firm_admin/user/remove-from-firm', { 
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				'firebaseToken': idToken,
				'targetUid': targetUid,
				'firmId': firmId,
			})
		}).then((response) => {
			if (!response.ok) {
				onError();
			} else {
				onSuccess();
			}
		}).catch((error) => { 
			onError();
			console.error(error); 
			throw error; 
		});
	});
}

export const canAllocateLicenseForFirm = (firmId, onSuccess, onError) => {
	const currentUser = firebase.auth().currentUser;
	if (!currentUser) {
		return Promise.reject("no user");
	}
	return currentUser.getIdToken(true).then(function(idToken) {
		return fetch(process.env.REACT_APP_FUNCTION_URI + '/firm_admin/invite/license-available', { 
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				'firebaseToken': idToken,
				'firmId': firmId,
			})
		}).then((response) => {
			if (!response.ok) {
				onError();
			} else {
				onSuccess();
			}
		}).catch((error) => { 
			onError();
			console.error(error); 
			throw error; 
		});
	});
}

export const allocateLawyerForFirm = (targetUid, firmId, onSuccess, onError) => {
	const currentUser = firebase.auth().currentUser;
	if (!currentUser) {
		return Promise.reject("no user");
	}
	return currentUser.getIdToken(true).then(function(idToken) {
		return fetch(process.env.REACT_APP_FUNCTION_URI + '/firm_admin/allocate/confirm', { 
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				'firebaseToken': idToken,
				'targetUid': targetUid,
				'firmId': firmId,
			})
		}).then((response) => {
			if (!response.ok) {
				onError();
			} else {
				onSuccess();
			}
		}).catch((error) => { 
			onError();
			console.error(error); 
			throw error; 
		});
	});
}

export const disallocateLawyerForFirm = (targetUid, firmId, onSuccess, onError) => {
	const currentUser = firebase.auth().currentUser;
	if (!currentUser) {
		return Promise.reject("no user");
	}
	return currentUser.getIdToken(true).then(function(idToken) {
		return fetch(process.env.REACT_APP_FUNCTION_URI + '/firm_admin/allocate/cancel', { 
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				'firebaseToken': idToken,
				'targetUid': targetUid,
				'firmId': firmId,
			})
		}).then((response) => {
			if (!response.ok) {
				onError();
			} else {
				onSuccess();
			}
		}).catch((error) => { 
			onError();
			console.error(error); 
			throw error; 
		});
	});
}

export const welcomeFoundingAdmin = (onSuccess, onError) => {
	const currentUser = firebase.auth().currentUser;
	if (!currentUser) {
		return Promise.reject("no user");
	}
	return currentUser.getIdToken(true).then(function(idToken) {
		return fetch(process.env.REACT_APP_FUNCTION_URI + '/firm_admin/welcome/founding-admin', { 
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				'firebaseToken': idToken,
			})
		}).then((response) => {
			if (!response.ok) {
				onError();
			} else {
				onSuccess();
			}
		}).catch((error) => { 
			onError();
			console.error(error); 
			throw error; 
		});
	});
}