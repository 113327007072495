import {
    ACTION_FIREBASE_AUTH_ID_TOKEN_RECEIVED,
    ACTION_FIREBASE_AUTH_SIGN_IN,
    ACTION_FIREBASE_AUTH_SIGN_OUT,
} from './type'

export function firebaseIdTokenReceived(idToken) {
    return {
        type: ACTION_FIREBASE_AUTH_ID_TOKEN_RECEIVED,
        idToken
    }
}

export function firebaseUserSignIn(user) {
    return {
        type: ACTION_FIREBASE_AUTH_SIGN_IN,
        user,
    }
}

export function firebaseUserSignOut() {
    return {
        type: ACTION_FIREBASE_AUTH_SIGN_OUT,
    }
}
