import React from "react";
import { withRouter } from "react-router";
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Translate from '../../translate/translate'

const useStyles = makeStyles(theme => ({
    root: {
        'border-bottom': `solid ${theme.mylawyer.background.medium}`,
        margin: '0 5%',
        'text-transform': 'none'
    },
    indicator: {
        display: 'none'
    },
}));

function MenuTabs(props) {
    const classes = useStyles();

    const tabLibrary = props.menu.map(currentMenuItem => {
        const label = <Translate value={currentMenuItem.label} />
        return <Tab
            classes={{ indicator: classes.indicator, root: classes.root }}
            component={Link}
            to={currentMenuItem.path}
            key={currentMenuItem.path}
            label={label}
            value={currentMenuItem.path} />
    });

    return (
        <Tabs
            classes={{ indicator: classes.indicator, root: classes.root }}
            textColor="primary"
            centered
            value={props.location.pathname} >
            {tabLibrary}
        </Tabs>
    )
}

export default withRouter(MenuTabs)
