import firebase from 'firebase/app'
import 'firebase/auth'

export const addLawyerByEmail = (email, message, firmId, shouldAllocateLicenseToLawyer, onSuccess, onError) => {

	const currentUser = firebase.auth().currentUser;
	if (!currentUser) {
		return Promise.reject("no user");
	}
	return currentUser.getIdToken(true).then(function(idToken) {
		return fetch(process.env.REACT_APP_FUNCTION_URI + '/firm_admin/invite/lawyer', { 
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				'firebaseToken': idToken,
				'email': email,
				'message': message,
				'firmId': firmId,
				'addLicense': shouldAllocateLicenseToLawyer
			})
		}).then((response) => {
			if (!response.ok) {
				return {};
			}
			return response.json();
		}).then((responseJson) => {
			onSuccess(responseJson.info);
		}).catch((error) => { 
			onError();
			console.error(error); 
			throw error;  //check if error can be handled
		});
	});
}

export const addFoundingAdminMembership = (firmId, onSuccess, onError) => {

	const currentUser = firebase.auth().currentUser;
	if (!currentUser) {
		return Promise.reject("no user");
	}
	return currentUser.getIdToken(true).then(function(idToken) {
		return fetch(process.env.REACT_APP_FUNCTION_URI + '/firm_admin/invite/founding-admin', { 
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				'firebaseToken': idToken,
				'firmId': firmId
			})
		}).then((response) => {
			if (!response.ok) {
				onError();
			} else {
				onSuccess();
			}
		}).catch((error) => { 
			onError();
			console.error(error); 
			throw error; 
		});
	});
}

export const inviteAdminByEmail = (email, firmId, onSuccess, onError) => {
	const currentUser = firebase.auth().currentUser;
	if (!currentUser) {
		return Promise.reject("no user");
	}
	return currentUser.getIdToken(true).then(function(idToken) {
		return fetch(process.env.REACT_APP_FUNCTION_URI + '/firm_admin/invite/admin', { 
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				'firebaseToken': idToken,
				'email': email,
				'firmId': firmId
			})
		}).then((response) => {
			if (!response.ok) {
				return {};
			}
			return response.json();
		}).then((responseJson) => {
			onSuccess(responseJson.info);
		}).catch((error) => { 
			onError();
			console.error(error); 
			throw error;  //check if error can be handled
		});
	});

}