import { combineReducers } from 'redux'
import { firebaseReducer } from 'react-redux-firebase'
import { translateReducer } from '../../translate/redux'
import userProfileReducer from './userProfile'
import appOpsReducer from './appOps'
import firebaseAuthReducer from './firebaseAuth'
import firmOpsReducer from './firmOps'
import billingReducer from './billing'
import { firestoreReducer } from 'redux-firestore'

// Add firebase to reducers
const rootReducer = combineReducers({
    firebase: firebaseReducer,
    translate: translateReducer,
    userProfile: userProfileReducer,
    appOps: appOpsReducer,
    firebaseAuth: firebaseAuthReducer,
    firmOps: firmOpsReducer,
    billing: billingReducer,
	firestore: firestoreReducer
});

export { rootReducer }
